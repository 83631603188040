import React, { useEffect, useRef, useState } from 'react'
import './index.less'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { changeIsLogin } from '../../../../../../store/modules/loginSlice'
import { courseClone, existOpen, getDetailCourse, getStudyWorkspace, getWorkspace } from '../../../../../../api/modules/study'
import VerifyLoading from '../../../../../ProductCourse/components/VerifyLoading'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { message, Modal, Tooltip } from 'antd';
import JoinModal from '../../../../../StudyLabCourse/components/ImportModal'
import { ifJoin, jionLabs, labsList } from '../../../../../../api/modules/product'
import * as url from '../../../../../../utils/url'
import { generateRandomTimeString } from '../../../../../../utils/randomTime'
import { getStudy } from '../../../../../../utils/url'

const { warning, confirm } = Modal;

export type DirOne = {
    id?: string,
    icon?: string,
    title?: string,
    highLight?: boolean
    type?: string
    path: string
}

type DirectoryOneProps = {
    course?: any
    code?: string
}

const DirectoryOne: React.FC<DirectoryOneProps> = (props) => {
    const { course } = props

    const { courseSpeed } = useSelector((state: any) => state.courseSpeed);

    const { courseName } = useSelector((state: any) => state.courseSpeed);


    const dispatch = useDispatch()
    const { userInfo } = useSelector((state: any) => state.loginUser)

    const { coucode } = useParams()

    const labcode = url.getLabCodeFromHost()

    const [params] = useSearchParams();
    let viewStatu = params.getAll("statu")[0]


    const [nowSpeed, setNowSpeed] = useState('')


    const [lodingValue, setLodingValue] = useState(false)


    //退出弹窗
    const exitConfirm = () => {
        warning({
            title: '温馨提示',
            icon: <ExclamationCircleFilled />,
            content: <p >当前课程工作台已开启，请到对应标签页进行学习!</p>,
            okText: '我已知晓',
            centered: true,
            onOk() {


            },
        });
    };

    // 加入实验室前，记录当前触发的小节名称，加入实验室后，直接跳转工作台
    const joinChapter = useRef('')

    //判断工作台是否开启
    const ifOpenWorkSpace = (chapter: any) => {
        const token = Cookies.get('token')
        if (!token || !userInfo?.userId) {
            dispatch(changeIsLogin({ isLogin: true }))
            return
        }
        if (isOpenJoin.isJoin) {
            existOpen({
                code: coucode,
                modal: 'read'
            }).then(res => {
                if (res.exist) {
                    exitConfirm()
                } else {
                    gotoWorkSpace(chapter)
                }
            }).catch(err => {
                console.log(err);
                // message.warning(`${err?.message},请重新进入！`)
            })
        } else {
            joinChapter.current = chapter
            showConfirm1()
        }

    }

    const showConfirm1 = () => {
        confirm({
            title: '加入实验室',
            icon: <ExclamationCircleFilled />,
            content: '您还未加入实验室无法开始学习，请先加入实验室～',
            okText: '加入实验室',
            cancelText: '我再看看',
            centered: true,
            onOk() {
                joinLab()
            },
            onCancel() {
            },
        });
    };

    // 是否点击加入实验室
    const [clickJion, setclickJion] = useState(false)

    const [openFromValue, setOpenFromValue] = useState([])


    let currentStep = params.getAll("stepCurrent")[0]

    const gotoWorkSpace = (chapter: any) => {
        console.log(chapter);
        const token = Cookies.get('token')
        if (!token || !userInfo?.userId) {
            dispatch(changeIsLogin({ isLogin: true }))
            return
        }
        if (typeof document.createElement === 'function') {
            // 删除所有<a>元素
            let links = document.querySelectorAll(".link-class")
            for (let i = links.length - 1; i >= 0; i--) {
                links[i].parentNode?.removeChild(links[i]);
            }
        }

        getDetailCourse({ code: coucode ?? props.code }).then(resss => {
            if (resss.statusCode !== 1 && !viewStatu) {
                Modal.warning({
                    title: '提示',
                    content: '当前课程已下架，若需学习，请同创建者联系~',
                    onOk: () => {
                        const url = getStudy()
                        if (url) {
                            window.open(url, '_self')
                        }
                        // window.open(window.location.origin, '_self')
                    },
                    centered: true,
                    okText: '我知道了'
                })
                return
            }
            if (nowSpeed) {
                if (!viewStatu && !currentStep) {
                    getStudyWorkspace({
                        code: coucode,
                        userid: userInfo.userId,
                        modal: 'read'
                    }).then(res => {
                        const { openApiAddr, sign, access, repo, partner } = res
                        const url = `${openApiAddr}?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(chapter) ?? ''}&courseName=${encodeURIComponent(courseName)}&time=${generateRandomTimeString()}`
                        let link = document.createElement('a');
                        link.classList.add("link-class");
                        link.href = url;
                        link.target = 'page';
                        let event = new MouseEvent('click', {
                            'view': window,
                            'bubbles': true,
                            'cancelable': true
                        });
                        link.dispatchEvent(event);
                    })
                    return
                }
                getWorkspace({
                    code: coucode,
                    userid: userInfo.userId,
                    modal: 'read'
                }).then(res => {
                    const { openApiAddr, sign, access, repo, partner } = res
                    const url = `${openApiAddr}?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(chapter) ?? ''}&courseName=${encodeURIComponent(courseName)}&preview=${1}&time=${generateRandomTimeString()}`
                    let link = document.createElement('a');
                    link.classList.add("link-class");
                    link.href = url;
                    link.target = 'page';
                    let event = new MouseEvent('click', {
                        'view': window,
                        'bubbles': true,
                        'cancelable': true
                    });
                    link.dispatchEvent(event);
                })
            } else {
                setLodingValue(true)
                courseClone({ code: coucode ?? props.code }).then(res => {
                    setLodingValue(false)
                    if (!viewStatu && !currentStep) {
                        getStudyWorkspace({
                            code: coucode,
                            userid: userInfo.userId,
                            modal: 'read'
                        }).then(res => {
                            const { openApiAddr, sign, access, repo, partner } = res
                            const url = `${openApiAddr}?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(chapter) ?? ''}&courseName=${encodeURIComponent(courseName)}&time=${generateRandomTimeString()}`
                            let link = document.createElement('a');
                            link.classList.add("link-class");
                            link.href = url;
                            link.target = 'page';
                            let event = new MouseEvent('click', {
                                'view': window,
                                'bubbles': true,
                                'cancelable': true
                            });
                            link.dispatchEvent(event);
                        })
                        return
                    }
                    getWorkspace({
                        code: coucode,
                        userid: userInfo.userId,
                        modal: 'read'
                    }).then(res => {
                        const { openApiAddr, sign, access, repo, partner } = res
                        const url = `${openApiAddr}?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(chapter) ?? ''}&courseName=${encodeURIComponent(courseName)}&preview=${1}&time=${generateRandomTimeString()}&time=${generateRandomTimeString()}`
                        let link = document.createElement('a');
                        link.classList.add("link-class");
                        link.href = url;
                        link.target = 'page';
                        let event = new MouseEvent('click', {
                            'view': window,
                            'bubbles': true,
                            'cancelable': true
                        });
                        link.dispatchEvent(event);
                    })
                }).catch(() => {
                    setLodingValue(false)
                    message.error('拉取课程失败')
                })
            }
        })
    }

    useEffect(() => {
        setNowSpeed(courseSpeed)
    }, [courseSpeed])

    //是否有表单需要填写
    const [isOpenJoin, setIsOpenJoin] = useState({
        isJoin: false,
        isOpenFrom: false,
    })

    const getJion = (callback?: Function) => {
        ifJoin({ labCode: labcode }).then(res => {
            setIsOpenJoin({
                isJoin: res.isJoin,
                isOpenFrom: res.isOpenFrom,
            })
            window.sessionStorage.setItem('isOpenJoin', JSON.stringify({
                isJoin: res.isJoin,
                isOpenFrom: res.isOpenFrom,
            }))
            callback?.()
        })
    }

    //加入实验室
    const joinLab = () => {
        if (!isOpenJoin.isJoin) {
            if (isOpenJoin.isOpenFrom) {
                labsList({ labCode: labcode }).then(res => {
                    setOpenFromValue(res)
                    setclickJion(true)
                })
            } else {
                jionLabs({ labCode: labcode, recordList: [] }).then(() => {
                    message.success('加入成功！')
                    getJion(() => {
                        gotoWorkSpace(joinChapter?.current ?? '')
                    })
                })
            }
        }
    }

    //判断用户是否加入实验室
    useEffect(() => {
        if (window.sessionStorage.getItem('isOpenJoin')) {
            let windowUploadList = JSON.parse(window.sessionStorage.getItem('isOpenJoin') || '')
            setIsOpenJoin(windowUploadList)
        }
    }, [window.sessionStorage.getItem('isOpenJoin')])

    return (
        <div className='one'>
            {
                course?.map((item: any) => {
                    return (
                        <div key={item.path} className={item.path === nowSpeed ? 'one-row one-highlight' : 'one-row'} onClick={() => { ifOpenWorkSpace(item.path) }}>
                            {item?.describe?.type === 'video' ? <img src={require('../../../../../../assets/images/videoIcon.png')} alt="" /> : <img src={require('../../../../../../assets/images/textIcon.png')} alt="" />}
                            <span className='one-row-title'>{item.title}</span>
                            {
                                item.path === nowSpeed ? <span className='one-highlight-learning'>正在学</span> : <></>
                            }
                            <Tooltip title={`当前学习进度：${item?.finishStatus}`} arrow={false} placement='bottom'color='#ffffff' overlayInnerStyle={{color: '#666666'}}>
                                <span className='one-row-procress'>{item?.finishStatus}</span>
                            </Tooltip>
                        </div>
                    )
                })
            }
            <JoinModal labCode={labcode ?? ''} show={clickJion && isOpenJoin.isOpenFrom} value={openFromValue} changeClick={(e: any) => {
                if (e) {
                    setclickJion(false)
                    getJion(() => {
                        gotoWorkSpace(joinChapter?.current ?? '')
                    })
                } else {
                    setclickJion(false)
                }
            }}></JoinModal>
            {lodingValue && <VerifyLoading data={'课程加载中......'}></VerifyLoading>}

        </div>
    )
}

export default DirectoryOne