import React, { useEffect, useState } from 'react'
import './index.less'
import { Button, Dropdown, Input, MenuProps, Modal, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import * as url from '../../utils/url'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { applyShortChain, getLogout, getShareInvitationCode } from '../../api/modules/common'
import { useLogin } from '../../hooks/Login'
import { useLocation, useNavigate } from 'react-router-dom'
import { changeIsLogin } from '../../store/modules/loginSlice'
import { getStudy, generateLabHomeUrl, getSecondHost, getHost, generateHomeUrl, getYunYanLoginSlient, getLocation } from '../../utils/url'
import { getLabToken } from '../../api/modules/issue'
import { getLabDetail } from '../../api/modules/study'
import Cookies from 'js-cookie'

interface HeaderProps {
    type?: 'base' | 'lab'    // header的类型  默认 base     实验室主页的头部为 lab
}

const Header: React.FC<HeaderProps> = (props) => {

    const { type = 'base' } = props

    //是否登录  登录的用户信息   
    const { loginIng, userInfo } = useSelector((state: any) => state.loginUser)
    const { logout } = useLogin()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    // 实验室编码
    const labcode = url.getLabCodeFromHost()

    // 实验室详情
    const [dataLab, setDataLab] = useState<any>()

    // 邀请码
    const [shareCode, setShareCode] = useState<string>('')
    // 分享人数
    const [shareNum, setShareNum] = useState<number>(0)
    // 邀请码弹框控制
    const [isShowShareCode, setIsShowShareCode] = useState(false)
    // 刷新按钮hover控制
    const [refreshHover, setRefreshHover] = useState(false)
    // 一个邀请码能够邀请的人数
    const [shareCodeNum, setShareCodeNum] = useState(1)

    // 导航栏  当前导航栏  
    const [tabs, setTabs] = useState<any>([
        {
            id: 0,
            title: '主页',
            url: ['/os/ma', '/os/ma/', '/os', '/os/'],  // 兼容以前的路由，将/os/ma设置为/os下的默认页
            onClick: () => {
                navigate('/os/ma')
            }
        },
        {
            id: 1,
            title: 'issue',
            url: '/os/is',
            onClick: () => {
                navigate('/os/is')
            }
        }
    ])

    const currentId = tabs.find((item: any) => Array.isArray(item?.url) ? item?.url?.includes(location?.pathname) : location?.pathname === item?.url)?.id ?? 1

    // 登录后的下拉菜单
    const dropDownMenu: MenuProps['items'] = [
        {
            label: '个人中心',
            key: '1',
            onClick: () => {
                if (labcode) {
                    window.open(generateHomeUrl(`personal`), "_self")
                } else {
                    navigate(`/personal`)
                }
            }
        },
        {
            label: '回到主页',
            key: '2',
            onClick: () => {
                if (labcode) {
                    window.open(generateHomeUrl(), "_self")
                } else {
                    navigate(`/`)
                }
            }
        },
        {
            label: '退出登录',
            key: '3',
            onClick: () => {
                Modal.confirm({
                    title: '确认退出登录？',
                    icon: <ExclamationCircleFilled />,
                    content: '退出登录后，下次进入需重新登录。',
                    okText: '我再想想',
                    centered: true,
                    cancelText: '确认退出',
                    onOk() { },
                    onCancel() {
                        getLogout().then(res => {
                        }).catch(() => { }).finally(() => {
                            // 退出登录需要做一些操作
                            logout()
                            // 退出登录后重定向到广场
                            if (labcode) {
                                window.open(generateHomeUrl('center'), "_self")
                            } else {
                                navigate(`/center`)
                            }
                        })
                    },
                })
            }
        }
    ]

    // 邀请好友邀请码
    const showShareCode = () => {
        getShareInvitationCode().then(res => {
            setShareCode(res.code)
            setShareNum(res.count)
            setShareCodeNum(res.number)
            setIsShowShareCode(true)
        }).catch(() => { })
    }

    // 刷新分享码
    const refresh = () => {
        getShareInvitationCode(true).then(res => {
            setShareCode(res.code)
            setShareNum(res.count)
            setShareCodeNum(res.number)
        }).catch(() => { })
    }

    // 复制分享码
    const copy = async () => {
        const res = await applyShortChain({
            inviteCode: shareCode,
            callback: getYunYanLoginSlient(`?type=register`),
            inviteContentType: 'labIndexInvite'
        }).catch(() => { })
        if (!res) return
        const value = res?.templateContent
        // const value = `和我一起加入开源学堂吧！${generateHomeUrl()}\n邀请码：${shareCode}`
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(value.trim())
        } else {
            const dom = document.createElement('input')
            dom.value = value.trim()
            document.body.appendChild(dom)
            dom.select()
            document.execCommand('copy')
            document.body.removeChild(dom)
        }
        message.destroy()
        message.success('复制成功')
    }

    // 获取实验室详情
    const fetchLabDetail = () => {
        getLabDetail({ code: labcode }).then(res => {
            setDataLab(res)
        })
    }

    useEffect(() => {
        if (labcode) {
            fetchLabDetail()
        }
    }, [labcode])

    // useEffect(() => {
    //     if (labcode) {
    //         getLabToken({
    //             labCode: labcode
    //         }).then(res => {
    //             res?.forEach((item: any) => {
    //                 if (item?.success) {
    //                     setTabs([
    //                         {
    //                             id: 0,
    //                             title: '主页',
    //                             url: ['/os/ma', '/os'],  // 兼容以前的路由，将/os/ma设置为/os下的默认页
    //                             onClick: () => {
    //                                 navigate('/os/ma')
    //                             }
    //                         },
    //                         {
    //                             id: 1,
    //                             title: 'issue',
    //                             url: '/os/is',
    //                             onClick: () => {
    //                                 navigate('/os/is')
    //                             }
    //                         }
    //                     ])
    //                 }
    //             })
    //         })
    //     }
    // }, [labcode])

    // 登录状态判断 
    const [login, setLogin] = useState(false)
    useEffect(() => {
        if (Cookies.get('token')) {
            setLogin(true)
        } else {
            setLogin(false)
        }
    }, [Cookies.get('token')])

    return (
        <div className='header-container'>
            <div className='header-container-content'>
                {/* <img onClick={() => { window.open(getStudy() as string, '_self') }} className='header-container-content-logo' src={require('../../assets/images/logo-college.png')} alt="" />
                {
                    labcode && dataLab && (
                        <div className='header-container-content-lablogo'>
                            <span></span>
                            <img src={dataLab?.logoUrl} alt="" onClick={() => { window.open(generateLabHomeUrl(labcode, 'os'))}} />
                        </div>
                    )
                } */}
                {
                    labcode && dataLab ? <>
                        <img onClick={() => { window.open(getStudy() as string, '_self') }} className='header-container-content-minilogo' src={require('../../assets/images/logomaozi.png')} alt="" />
                        <div className='header-container-content-lablogo'>
                            <span></span>
                            <img src={dataLab?.logoUrl} alt="" onClick={() => { window.open(generateLabHomeUrl(labcode, 'os')) }} />
                        </div>
                    </> : <img onClick={() => { window.open(getStudy() as string, '_self') }} className='header-container-content-logo' src={require('../../assets/images/logo-college.png')} alt="" />
                }
                <div className='header-container-content-center'>
                    {
                        type === 'lab' && <div className='header-container-content-center-nav'>
                            <div className='header-container-content-center-nav-tabs'>
                                {
                                    tabs?.map((item: any) => {
                                        return <span onClick={() => { item?.onClick?.() }} key={item?.id}>{item?.title}</span>
                                    })
                                }
                                <div className='header-container-content-center-nav-tabs-line' style={{ left: currentId * (60 + 34) + 'px' }}></div>
                            </div>
                        </div>
                    }
                </div>
                <div className='header-container-content-right'>
                    {
                        login ? <div className="header-container-content-right-login">
                            {
                                userInfo?.userInvite && <>
                                    <div className='sharefriend' onClick={showShareCode}>
                                        <img src={require('../../assets/images/add-circle-line-active.png')} alt="" />
                                        <span>邀请好友</span>
                                    </div>
                                    <Modal
                                        open={isShowShareCode}
                                        wrapClassName={'wsharefriend-modal'}
                                        onCancel={() => { setIsShowShareCode(false) }}
                                        footer={false}
                                        closable={true}
                                        centered={true}
                                        width={'496px'}
                                        maskClosable={false}
                                        destroyOnClose={true}
                                    >
                                        <div className='wsharefriend-modal-title'>邀请好友</div>
                                        <div className='wsharefriend-modal-con'>
                                            <img src={require('../../assets/images/Icon-warning.png')} alt="" />
                                            <div>
                                                <p>1.每个邀请码仅可使用{shareCodeNum}次，使用后失效；</p>
                                                <p>2.点击刷新则生成新的邀请码。</p>
                                            </div>
                                        </div>
                                        <div className='wsharefriend-modal-btn'>
                                            <div style={{ width: '362px', marginRight: '10px' }}>
                                                <Input prefix={
                                                    <div className='wsharefriend-modal-btn-share'>我的邀请码：</div>
                                                } suffix={
                                                    <div className='wsharefriend-modal-btn-new' onClick={refresh} onMouseEnter={() => { setRefreshHover(true) }} onMouseLeave={() => { setRefreshHover(false) }}>
                                                        {
                                                            refreshHover ? <img src={require('../../assets/images/loop-left-line-hover.png')} alt="" /> :
                                                                <img src={require('../../assets/images/loop-left-line.png')} alt="" />
                                                        }
                                                    </div>
                                                }
                                                    value={shareCode}
                                                    disabled />
                                            </div>
                                            <button onClick={copy}>复 制</button>
                                        </div>
                                        <div className='wsharefriend-modal-people'>
                                            <img src={require('../../assets/images/share-people.png')} alt="" />
                                            <span>已邀请：{shareNum}人</span>
                                        </div>
                                    </Modal>
                                </>
                            }
                            <div className='header-container-content-right-login-user'>
                                <img className='header-container-content-right-login-user-logo' src={userInfo?.avatar ? userInfo?.avatar : require('../../assets/images/signlogo.png')} alt="" />
                                <Dropdown
                                    menu={{ items: dropDownMenu }}
                                >
                                    <div className='header-container-content-right-login-user-name'>
                                        <span>{userInfo?.userphone}</span>
                                        <img src={require('../../assets/images/bot.png')} alt="" />
                                    </div>
                                </Dropdown>
                            </div>
                        </div> : <Button type='primary' style={{ borderRadius: '2px', minWidth: '104px' }} onClick={() => {
                            window.open(getYunYanLoginSlient(`?redirect=${getLocation()}`), '_self')
                        }}>登录/注册</Button>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header