import axios, { AxiosRequestConfig } from 'axios'
import { message } from 'antd';
import Config from '../config'
import Cookies from 'js-cookie';
import { generateHomeUrl, getLocation, getYunYanLoginSlient } from '../utils/url';


// 设置请求头和请求路径
axios.defaults.baseURL = Config.baseURL
axios.defaults.timeout = Config.requestTimeOut
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const token = Cookies.get('token')
    const sign = Cookies.get('sign')
    const partner = Cookies.get('partner')
    if (token && config.headers && config.url?.indexOf('api.github.com') === -1) {
      config.headers.token = token
    }
    if (sign) {
      config.headers.sign = sign
    }
    if (partner) {
      config.headers.partner = partner
    }
    return config
  },
  error => {
    setTimeout(() => {
      message.error(error || '请求错误')
    }, 0);
    // return Promise.reject(error)
  }
)

// 响应拦截
axios.interceptors.response.use(
  res => {
    return res.data
  },
  error => {
    const { response } = error
    const { status } = response ?? {}
    if (status === 401) {
      const data = new URL(window.location.href)
      const query = data.searchParams
      const moka = query?.get('login') === 'moka'
      if (moka) {
        window.open(getYunYanLoginSlient(`?type=onlylogin&redirect=${getLocation()}`), '_self')
      } else {
        window.open(getYunYanLoginSlient(`?redirect=${getLocation()}`), '_self')
      }
    }
    if (response) {
      const { data } = response
      return Promise.reject(data)
    } else {
      return Promise.reject(error)
    }
  }
)

interface Http {
  get<T>(url: string, params?: T, config?: AxiosRequestConfig): Promise<any>
  post<T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<any>
  put<T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<any>
  delete<T>(url: string, params?: T, config?: AxiosRequestConfig): Promise<any>
}

const http: Http = {
  get(url, params, config) {
    return axios.get(url, { params, ...config })
  },
  post(url, data, config) {
    let d
    if (config?.headers?.['Content-Type'] === 'multipart/form-data') {
      d = data
    } else {
      d = JSON.stringify(data)
    }
    return axios.post(url, d, config)
  },
  put(url, data, config) {
    return axios.put(url, JSON.stringify(data), config)
  },
  delete(url, params, config) {
    return axios.delete(url, { params, ...config })
  }
}
export default http


// 该接口不经过响应拦截器，因为需要获取响应头
export const httpInstanve = axios.create({
  baseURL: Config.baseURL,
  timeout: Config.requestTimeOut
})
httpInstanve.interceptors.request.use(
  config => {
    const token = Cookies.get('token')
    const sign = Cookies.get('sign')
    const partner = Cookies.get('partner')
    if (token && config.headers && config.url?.indexOf('api.github.com') === -1) {
      config.headers.token = token
    }
    if (sign) {
      config.headers.sign = sign
    }
    if (partner) {
      config.headers.partner = partner
    }
    return config
  },
  error => {
    setTimeout(() => {
      message.error(error || '请求错误')
    }, 0);
    // return Promise.reject(error)
  }
)

httpInstanve.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'