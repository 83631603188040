import React, { useState, useEffect } from 'react'
import "./index.less"
import * as url from '../../../../utils/url'
import { Checkbox, Modal, Pagination, Tooltip, message } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { deleteProblem, getLabToken, issueLabels, issueStatus, syncIssue } from '../../../../api/modules/issue';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLabDetail } from '../../../../api/modules/product';
import { getUserInfo } from '../../../../api/modules/person';
import { getLabAuth } from '../../../../api/modules/admin';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { changeIsLogin } from '../../../../store/modules/loginSlice';
import { courseClone, existOpen, getDetailCourse, getStudyCourseStatus, getStudyWorkspace, getWorkInvite } from '../../../../api/modules/study';
import { generateRandomTimeString } from '../../../../utils/randomTime';

const { warning, confirm } = Modal;


type RemarkRigthProps = {
  value?: any
  getValue?: () => void //重新请求数据
  joinLab?: () => void   //加入实验室
  gitNo?: () => void    //添加权限
  setLodingValue?: (value: any) => void    //是否处于克隆中
  ifJoin?: any
}



const RemarkRigth: React.FC<RemarkRigthProps> = (props) => {
  const { userInfo } = useSelector((state: any) => state.loginUser)
  const dispatch = useDispatch()

  const newLabcode = url.getLabCodeFromHost()
  const [search] = useSearchParams()
  const code = search.get('code') || ''
  let id = search.get('id') || ''

  const { coucode } = useParams()
  const labcode = search.get('labCode') || ''

  const { value, getValue, ifJoin, joinLab, setLodingValue } = props

  // 路由导航
  const navigate = useNavigate()

  //实验室是否有配置github
  const [ifGitHub, setIfGitHub] = useState(false)

  //实验室详情
  const [labDetail, setLabDetail] = useState<any>({

  })
  //判断是否为同步中
  const [ifSync, setIfSync] = useState(false)

  const truncateString = (str: any) => {
    if (str.length > 6) {
      return str.substring(0, 6) + "...";
    } else {
      return str;
    }
  }


  //删除
  const deteleRemark = () => {

    Modal.confirm({
      title: '确认删除？',
      icon: <ExclamationCircleFilled />,
      content: '删除后，内容将无法恢复，请谨慎操作。',
      okText: '确认',
      centered: true,
      width: 400,
      cancelText: '取消',
      onOk() {
        console.log(
          {
            id,
            labCode: labcode ? labcode : newLabcode,
            code: code ? code : coucode
          }
        );

        deleteProblem({
          id,
          labCode: labcode ? labcode : newLabcode,
          code: code ? code : coucode
        }).then(res => {
          message.destroy()
          message.success('删除成功')
          navigate(-1)
          localStorage.setItem("courseTab", "2");


        }).catch((err) => {
          message.warning(err.message)
        })
      },
      onCancel() {

      },
    })
  }

  //进入小节

  const ifOpenWorkSpace = () => {

    const token = Cookies.get('token')
    if (!token || !userInfo?.userId) {
      dispatch(changeIsLogin({ isLogin: true }))
      return
    }

    if (ifJoin) {
      existOpen({
        code: code ? code : coucode,
        modal: 'read'
      }).then(res => {
        if (res.exist) {
          exitConfirm()
        } else {
          gotoWorkSpace()
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      joinLab?.()
    }
  }

  //退出弹窗
  const exitConfirm = () => {
    warning({
      title: '温馨提示',
      icon: <ExclamationCircleFilled />,
      content: <p >当前课程工作台已开启，请到对应标签页进行学习!</p>,
      okText: '我已知晓',
      centered: true,
      onOk() {


      },
    });
  };
  // 前往工作台
  const gotoWorkSpace = () => {
    console.log(typeof document.createElement === 'function');

    if (typeof document.createElement === 'function') {
      // 删除所有<a>元素
      let links = document.querySelectorAll(".link-class")
      for (let i = links.length - 1; i >= 0; i--) {
        links[i].parentNode?.removeChild(links[i]);
      }
    }
    getStudyCourseStatus({ code: coucode ?? code }).then((res: any) => {
      if (res.speed) {
        getStudyWorkspace({
          code: coucode ?? code,
          userid: userInfo.userId,
          modal: 'read'
        }).then(res => {
          const { openApiAddr, sign, access, repo, partner } = res
          const url = `${openApiAddr}/?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(value?.chapter || '')}&courseName=${encodeURIComponent(res.name || '')}&time=${generateRandomTimeString()}`
          let link = document.createElement('a');
          link.href = url;
          link.classList.add("link-class");
          link.target = 'page';
          let event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
          });
          link.dispatchEvent(event);
        })
        return
      } else {
        setLodingValue?.(true)
        courseClone({ code: coucode }).then(res => {
          setLodingValue?.(false)
          getStudyWorkspace({
            code: coucode ?? code,
            userid: userInfo.userId,
            modal: 'read'
          }).then(res => {
            const { openApiAddr, sign, access, repo, partner } = res
            const url = `${openApiAddr}?modal=0&repo=${repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(value?.chapter || '')}&courseName=${encodeURIComponent(res.name || '')}&time=${generateRandomTimeString()}`
            let link = document.createElement('a');
            link.classList.add("link-class");
            link.href = url;
            link.target = 'page';
            let event = new MouseEvent('click', {
              'view': window,
              'bubbles': true,
              'cancelable': true
            });
            link.dispatchEvent(event);
            // window.open(url, '_blank')
          })
        }).catch(() => {

          message.error('拉取课程失败')
        })
      }
    }).catch(() => {

    })
  }

  //同步
  const putIssue = () => {
    //是否同步完成 是否处于同步中
    if (value?.sync || ifSync) {
      return
    }
    if (!value?.isProblemAuthor) {
      message.warning('只有作者本人才能进行同步！')
      return
    }
    //是否有配置github
    if (!ifGitHub) {
      message.warning('当前实验室未激活issue，无法同步!')
      return
    }

    syncIssue({
      cooperateUrl: process.env.REACT_APP_ENV === 'production' ? `https://${labcode ? labcode : newLabcode}.beta.oscollege.net/os/${code ? code : coucode}` : `https://${labcode ? labcode : newLabcode}.test.oscollege.net/os/${code ? code : coucode}`,
      id,
      labCode: labcode ? labcode : newLabcode,
      code: code ? code : coucode,
    }).then(res => {
      setIfSync(true)
      setTimeout(() => {
        issueStatu()
      }, 1000);
    }).catch(err => {
      if (err.message === '用户未绑定个人github令牌') {
        gitPersonal()
      }
    })
  }

  const gitPersonal = () => {

    Modal.confirm({
      title: '暂无权限',
      icon: <ExclamationCircleFilled />,
      content: '暂时无法操作，请先绑定GitHub账号并设置访问令牌~',
      okText: '去设置  ',
      centered: true,
      width: 400,
      cancelText: '我再想想',
      onOk() {
        navigate(`/personal`)
      },
      onCancel() {

      },
    })
  };

  //同步状态
  const issueStatu = () => {
    issueStatus({
      id,
    }).then(res => {
      if (res.sync) {
        setTimeout(() => {
          issueStatu()
          setIfSync(res.sync)
        }, 1000);
      } else {
        getValue?.()
        message.success('同步成功！')

      }
    })
  }

  //去学习
  const joinStudy = () => {

    if (Cookies.get('token')) {
      // jumpWork()
      const url = value.cooperateUrl
      let link = document.createElement('a');

      link.classList.add("link-class");
      link.href = url;
      link.target = 'page';
      let event = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      link.dispatchEvent(event);
    } else {
      dispatch(changeIsLogin({ isLogin: true }))
    }
  }

  //被邀请者跳转工作台
  const jumpWork = () => {
    getWorkInvite({
      repo: decodeURIComponent(value?.repo),
      sign: decodeURIComponent(value?.sign),
    }).then(res => {
      const { openApiAddr, access, partner, courseName } = res
      const url = `${openApiAddr}?modal=0&repo=${value?.repo}&userId=${Cookies.get('userId') ?? ''}&key=${access}&partner=${Cookies.get('partner') ?? ''}&token=${Cookies.get('token') ?? ''}&sign=${encodeURIComponent(Cookies.get('sign') ?? '')}&chapter=${encodeURIComponent(value?.chapter || '')}&courseName=${encodeURIComponent(courseName)}&time=${generateRandomTimeString()}`
      let link = document.createElement('a');

      link.classList.add("link-class");
      link.href = url;
      link.target = 'page';
      let event = new MouseEvent('click', {
        'view': window,
        'bubbles': true,
        'cancelable': true
      });
      link.dispatchEvent(event);
    })
  }



  useEffect(() => {
    if (labcode || newLabcode) {
      getLabDetail({ labCode: labcode ? labcode : newLabcode }).then((res: any) => {
        setLabDetail(res)
      })
      getLabToken({ labCode: labcode ? labcode : newLabcode }).then(res => {
        res?.forEach((item: any) => {
          if (item.success) {
            setIfGitHub(true)
          }
        })
      })
    }

  }, [labcode, newLabcode])

  return (
    <div className='answer-remark-rigth'>
      {value?.labels?.length > 0 && <div className='orderSelect'>
        <div className='orderSelect-top'>
          <div>标签</div>

        </div>

        {<div className='orderList'>
          {value?.labels?.map((item: any) => {
            return (
              <span key={item?.color} className='orderItem' style={{ background: `#E1E9F5` }}>{item}</span>
            )
          })}
          {
            value?.labels?.length === 0 && <span style={{ fontSize: '12px', color: '#333333' }}>暂无</span>
          }
        </div>
        }
      </div>}
      {value && <div className='orderSelect'>
        <div className='orderSelect-top'>
          <div>{value?.courseDetail?.type === 'BLOG' ? '所属博客' : '所属小节'}</div>
        </div>
        <div style={{ fontSize: '12px', cursor: 'pointer', color: '#1677FF', textDecoration: 'underline' }}
          onClick={() => {
            ifOpenWorkSpace()
          }}
        >{truncateString(value?.chapter)}</div>
      </div>}
      {value?.cooperateUrl && <div className='orderSelect'>
        <div className='orderSelect-top'>
          <div>协同链接</div>
        </div>
        <div style={{ fontSize: '12px', cursor: 'pointer', color: '#1677FF', textDecoration: 'underline' }} onClick={() => {
          joinStudy()
        }}>点击进入协同</div>
      </div>}
      {(value?.isProblemAuthor || labcode) && <div className='orderOption'>
        <div className='detele' onClick={deteleRemark}>删除</div>
        <div className='tongbu' style={{
          background: value?.isProblemAuthor && ifGitHub && !value?.sync ? '#1677FF' : '#CCCCCC',
          cursor: value?.isProblemAuthor && !value?.sync && !ifSync ? 'pointer' : 'not-allowed'
        }} onClick={putIssue}>{!value?.sync ? ifSync ? '同步中...' : '同步为issue' : '已同步issue'}</div>
      </div>}
    </div>
  )
}

export default RemarkRigth;

